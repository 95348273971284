import React from "react";
import Layout from "../components/layout/Layout";
import NavBar from "../components/navigations/NavBar";
import FootBar from "../components/navigations/FootBar";
import MiddleOne from "../components/navigations/MiddleOne";
import {Link} from "gatsby";
import {useTranslation} from "react-i18next";
import "../translations/i18n";
import ReactTooltip from 'react-tooltip';
import {getDemarchesSepro, getMissionsSepro, getValeursSepro} from "../utils/Common";
import {StaticImage} from "gatsby-plugin-image"

const About = () => {
    const {t} = useTranslation();
    const valeurs = getValeursSepro();
    const demarches = getDemarchesSepro();
    return (
        <Layout pageTitle={t("qui_sommes_nous")}>
            <div className="min-h-full bg-about">
                <NavBar active={'about'}/>

                <div className="px-4 py-20 sm:p-10 bg-sepro-about-1">
                    <div
                        className="relative flex flex-col items-start md:items-center max-w-7xl px-6 py-8 mx-auto text-left sm:my-10 lg:px-0 ">
                        <h1 className="max-w-2xl text-4xl font-extralight text-white lg:text-6xl uppercase">{t("discover")}</h1>
                        <h1 className="max-w-2xl text-4xl font-extralight text-white lg:text-6xl uppercase">{t("le")}
                            <span className="font-extrabold font-family-title">{t("groupe_sepro")}</span></h1>
                    </div>
                </div>
                <div className="relative flex flex-col items-center max-w-full mx-auto text-justify py-2">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 border-none">
                        <div
                            className="relative border-none px-8 md:px-14  flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                            <div
                                className="text-justify relative flex flex-col items-start max-w-7xl px-6 mx-auto text-left md:my-4 lg:px-20 mt-4 text-left">
                                <h2 className="max-w-2xl text-xs md:text-base p-2 font-bold text-white bg-cyan-900">{t("why_sepro")}</h2>
                                <p className="mt-2 text-sm md:text-base text-gray-900 mt-8 md:mt-4 text-justify">{t("home_msg_7")}</p>
                                <ul className="pl-5 md:pl-10 mt-5 text-base text-cyan-900 list-disc text-left">
                                    <li className="mt-1 list-item mb-4 text-sm md:text-base">
                                        <span className="text-gray-900 font-bold">{t("sepro_solution")} </span>
                                        <span className="text-gray-900 lowercase">{t("spec_sepro_solution")}</span>
                                    </li>
                                    <li className="mt-4 list-item text-sm md:text-base">
                                        <span className="text-gray-900 font-bold">{t("sepro_technology")} </span>
                                        <span className="text-gray-900 lowercase">{t("spec_sepro_tech")}</span>
                                    </li>
                                </ul>
                                <p className="text-base text-gray-900 mt-4 md:mt-10 text-sm md:text-base">{t("about_bref_1")}</p>
                                <p className="mt-4 text-base text-gray-900 text-sm md:text-base">{t("about_bref_2")}</p>
                            </div>
                        </div>

                        <div
                            className="relative border-none py-5 flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500 mx-auto">
                            <div className="flex-1 flex-center min-w-0">
                                <StaticImage
                                    src={"../../static/qui_sommes_nous/about-header.png"}
                                    alt={t("spero")}
                                    className="object-cover object-center "
                                    height="400"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="relative flex flex-col items-center bg-gray-50 max-w-full">
                    {/* filial */}
                    <ul role="list"
                        className="grid grid-cols-1 lg:gap-18 md:gap-16 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 py-8">
                        <li className="mt-4 py-8 col-span-1 flex flex-col text-center  rounded-lg shadow divide-y divide-gray-200 relative border-b-4 border-lime-400 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                            <div className="flex-1 flex flex-col p-8 w-96 ">
                                <StaticImage
                                    src={"../../static/logos/logo-sepro-solution.png"}
                                    alt={t("sepro_solution")}
                                    className="object-cover object-center w-full h-full"/>
                                <dl className=" flex-grow flex flex-col justify-between">
                                    <div className="relative flex flex-col items-center mt-5 md:pt-14 pb-10">
                                        <Link to={'/sepro-solution'}
                                              className="inline-flex items-center justify-center max-w-fit inline px-8 py-3 mt-8 text-base font-medium bg-cyan-900 border border-transparent text-slate-100 hover:bg-teal-600"
                                        >
                                            <svg width="8" height="12" viewBox="0 0 7 11" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.75 1L5.32 5.57L0.75 10.14" stroke="white" stroke-width="1.5"
                                                      stroke-miterlimit="10" stroke-linecap="round"
                                                      stroke-linejoin="round"/>
                                            </svg>
                                            <span className="ml-2">{t("read_more")}</span>
                                        </Link>
                                    </div>

                                </dl>
                            </div>
                        </li>
                        <li className="mt-4 py-8 col-span-1 flex flex-col text-center relative border-b-4 border-cyan-500 bg-white px-6 py-5 flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-indigo-500">
                            <div className="flex-1 flex flex-col p-8 w-full ">
                                <StaticImage
                                    src={"../../static/logos/logo-sepro-tech.png"}
                                    alt={t("speor_technology")}
                                    width={320}
                                    height={128}
                                />
                                <dl className=" flex-grow flex flex-col justify-between">
                                    <div className="relative flex flex-col items-center md:pt-14 pb-10">
                                        <Link to={'/sepro-technology'}
                                              className="inline-flex items-center justify-center max-w-fit inline px-8 py-3 mt-8 text-base font-medium bg-cyan-900 border border-transparent text-slate-100 hover:bg-teal-600"
                                        >
                                            <svg width="7" height="11" viewBox="0 0 7 11" fill="none"
                                                 xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0.75 1L5.32 5.57L0.75 10.14" stroke="white" stroke-width="1.5"
                                                      stroke-miterlimit="10" stroke-linecap="round"
                                                      stroke-linejoin="round"/>
                                            </svg>
                                            <span className="ml-2">{t("read_more")}</span>
                                        </Link>
                                    </div>

                                </dl>
                            </div>
                        </li>

                    </ul>
                    {/* end filial */}

                    {/* notre demarche block*/}
                    <div className="relative flex flex-col items-center max-w-full md:mx-28">
                        <div className="flex-1 min-w-full">
                            <StaticImage
                                src={"../../static/qui_sommes_nous/notre_demarche.jpg"}
                                alt={t("nos_demarches")}
                                className="object-cover object-center w-full h-full"
                            />
                        </div>
                    </div>
                    <div className="container max-w-full mx-auto md:pl-28">
                        <div className="container max-w-full mx-auto bg-about-demarche p-4 md:p-10">
                            <div
                                className="relative flex flex-col items-start max-w-full px-6 my-2 mx-auto text-left sm:my-4 lg:px-20 ">
                                <h2 className="max-w-2xl text-sm md:text-base p-2 font-bold text-white bg-cyan-900 uppercase">{t("la_demarche_sepro")}</h2>
                            </div>
                            <div className="mx-auto py-2  md:px-4 sm:px-6 lg:max-w-7xl lg:px-8">
                                {/*containe demarche*/}
                                <div className="min-w-full border-b-2 border-gray-100 mb-8">&nbsp;</div>
                                {demarches.map((demarche, index) => (<>
                                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                                            <div className="relative px-8  shadow-sm flex items-center space-x-3">
                                                <div className="flex-shrink-0 px-5">
                                                    <svg width="63" height="46" viewBox="0 0 63 46" fill="none"
                                                         xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M40.2194 1L62 22.7806L40.2194 44.5612"
                                                              stroke="#47BBCD" stroke-width="2"
                                                              stroke-miterlimit="10" stroke-linecap="round"
                                                              stroke-linejoin="round"/>
                                                        <path opacity="0.4" d="M1 22.7808H61.39"
                                                              stroke="#47BBCD" stroke-width="2"
                                                              stroke-miterlimit="10" stroke-linecap="round"
                                                              stroke-linejoin="round"/>
                                                    </svg>
                                                </div>
                                                <div className="flex-1 min-w-0">
                                                    <p className="text-cyan-900 font-bold text-1xl md:text-3xl md:px-10 py-2 text-bold leading-6">{t(demarche.title)}</p>
                                                </div>
                                            </div>
                                            <div
                                                className="relative px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                <div className="flex-1 min-w-0">
                                                    {demarche.points.map((point) => (
                                                        <p className="text-sm md:text-base md:mb-1 text-about leading-6 text-gray-200">{t(point)}</p>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        {demarches.length > index + 1 ? <div
                                            className="min-w-full border-b-2 border-gray-100 mb-8">&nbsp;</div> : ''}
                                    </>
                                ))}
                                {/*containe demarche*/}
                            </div>
                        </div>

                    </div>
                    {/* end  notre demarche block*/}

                    <div className="container max-w-full mx-auto bg-transparent z-20 -mb-20 md:-mb-44">
                        <div className="mx-auto pt-12 px-4 sm:pt-20 lg:max-w-7xl">
                            {/* our philosophy block*/}
                            <div className="lg:grid lg:grid-rows-1 lg:grid-cols-7 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
                                {/* Product image */}
                                <div className="lg:row-end-1 lg:col-span-4">
                                    <h1 className="max-w-2xl text-4xl my-2 font-extralight tracking-tight text-gray-900 md:text-6xl uppercase">Notre</h1>
                                    <h1 className="max-w-2xl text-4xl font-bold tracking-tight text-cyan-900 md:text-6xl uppercase">philosophie</h1>
                                    <div className="grid grid-cols-1">
                                        <div className="grid grid-cols-1 mb-10">
                                            <div className="flex-1 min-w-full">
                                                <p className="text-sm md:text-base mb-1 text-left my-10">{t('philosophy')}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Product details */}
                                <div
                                    className="max-w-2xl mx-auto mt-20 sm:mt-16 lg:max-w-none  lg:row-end-2 lg:row-span-2 lg:col-span-3 h-23">
                                    <div className="maclasse pb-20">
                                        <StaticImage
                                            src={"../../static/qui_sommes_nous/philo-valeurs.jpg"}
                                            alt=""
                                            className="object-cover object-center w-full  justify-end h-2/2"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container max-w-full mx-auto bg-gray-900 -mt-96">
                        <div className="mx-auto pb-16 px-4  lg:max-w-7xl lg:px-8">
                            {/* Product */}
                            <div className="lg:grid lg:grid-rows-1 lg:grid-cols-7 lg:gap-x-8 lg:gap-y-10 xl:gap-x-16">
                                {/* Product image */}
                                <div className="lg:row-end-1 lg:col-span-4 mt-20 md:mt-0">
                                    <h1 className="max-w-2xl text-5xl my-4 font-extralight tracking-tight text-gray-200 lg:text-6xl uppercase">{t("our")}</h1>
                                    <h1 className="max-w-2xl text-5xl mb-10 font-bold tracking-tight text-cyan-900 lg:text-6xl uppercase">{t("valeurs")}</h1>
                                    <div className="grid grid-cols-3 gap-4 mb-10 ">
                                        {valeurs.map((valeur, index) => (
                                            <>
                                                {index < 6 ?
                                                    <div className="grid grid-cols-1 mb-10">
                                                        <div className="flex-1 min-w-full">
                                                            <img
                                                                src={"qui_sommes_nous/" + valeur.icon}
                                                                alt={t(valeur.title)}
                                                                className="object-cover object-center mx-auto w-auto block"
                                                            />
                                                            <p className="text-base mb-1 text-center text-gray-200 my-10">{t(valeur.title)}</p>
                                                        </div>
                                                    </div>
                                                    : <></>
                                                }
                                            </>

                                        ))}

                                    </div>
                                </div>

                                {/* Product details */}
                                <div
                                    className="max-w-2xl mx-auto mt-14 sm:mt-16 lg:max-w-none lg:mt-0 lg:row-end-2 lg:row-span-2 lg:col-span-3">
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                {/* end filial */}

                <MiddleOne/>

                <FootBar active={'solution'}/>
            </div>
            <ReactTooltip/>
        </Layout>
    );
}

export default About;
